import React from 'react';
import { Layout } from '../components/layout';

const NotFoundPage = () => (
    <Layout title={'Page Not Found'}>
        <h1>NOT FOUND</h1>
        <p>
            We could not find the <code>pixels</code> you were looking for.
        </p>
        <blockquote>All hope is not lost!</blockquote>
        <p>There are others waiting for your attention.</p>
    </Layout>
);

export default NotFoundPage;
